import React from 'react';
import Button from '@mui/material/Button';

function HomePage() {
  return (
    <div style={{ backgroundColor: '#e59937', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <h1>UOSAG: Students representing students</h1>
      <Button variant="contained" sx={{ backgroundColor: '#7289DA', '&:hover': { backgroundColor: '#5a6fa8' } }} onClick={() => window.open('https://discord.gg/Rx4nSjj9', '_blank')}>Join discord server</Button>
    </div>
  );
}

export default HomePage;